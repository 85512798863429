<template>
  <div class="privacy-policy-wrapper">
    <div class="container">
      <h1 class="head-text">Сведения об образовательной организации</h1>

      <div>
        <h2 @click="openBlock">Основные сведения</h2>
        <div class="content">
          <h3>Полное наименование в соответствии с Уставом:</h3>

          <p>
            Общество с ограниченной ответственностью «Лаборатория эмоционального
            интеллекта».
          </p>

          <h3>Сокращенное наименование:</h3>

          <p>ООО "Лаборатория ЭИ"</p>

          <p>Дата создания: 01.12.2017 г.</p>

          <p>
            Информация о месте нахождения образовательной организации: 121205,
            Г.МОСКВА, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ МОЖАЙСКИЙ, ТЕР СКОЛКОВО
            ИННОВАЦИОННОГО ЦЕНТРА, Б-Р БОЛЬШОЙ, Д. 42 СТР.1, ЭТАЖ 4, ПОМЕЩ. 88
          </p>

          <p>
            Место осуществление образовательной деятельности: 121205, Г.МОСКВА,
            ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ МОЖАЙСКИЙ, ТЕР СКОЛКОВО ИННОВАЦИОННОГО
            ЦЕНТРА, Б-Р БОЛЬШОЙ, Д. 42 СТР.1, ЭТАЖ 4, ПОМЕЩ. 88
          </p>

          <p>
            Учредители: <br />Хлевная Елена Анатольевна<br />Елманов Олег
            Александрович
          </p>

          <p>
            Информация о представительствах и филиалах: Представительств и
            филиалов нет
          </p>

          <p>
            Информация о режиме и графике работы образовательной организации:
            Понедельник – пятница с 9.00 - 18.00
          </p>

          <p>
            Информация о контактных телефонах образовательной организации:
            <a href="tel:+74959717302">8(495) 971-73-02</a>
          </p>

          <p>
            Информация об адресах электронной почты образовательной организации:
            <br />
            <a href="mailto:connect@eilab.ru">connect@eilab.ru</a>
          </p>
        </div>
      </div>
      <div>
        <h2 @click="openBlock">Структура и органы управления</h2>
        <div class="content">
          <p>Органами управления являются:</p>
          <ul>
            <li>Совет Учредителей</li>
            <li>Генеральный директор</li>
          </ul>
        </div>
      </div>
      <div>
        <h2 @click="openBlock">Документы</h2>
        <div class="content">
          <p>
            <a href="https://disk.yandex.ru/i/VG50jir7UQafxg" target="_blank"
              >Лицензия на осуществление образовательной деятельности
            </a>
          </p>

          <p>
            <a href="https://disk.yandex.ru/i/bveMtxcZ9glw-A" target="_blank"
              >Устав ООО «Лаборатория ЭИ»</a
            >
          </p>

          <h3>Локальные акты</h3>

          <p>
            <a href="https://disk.yandex.ru/i/UKaXb2BwHTdUvg" target="_blank"
              >Правила внутреннего распорядка обучающихся</a
            >
          </p>
          <p>
            <a href="https://disk.yandex.ru/d/hseXIstZmygaQg" target="_blank">
              Правила внутреннего трудового распорядка работников</a
            >
          </p>
          <p>
            <a href="https://disk.yandex.ru/i/Gl5koo3iZ1uP_w" target="_blank">
              Правила приема обучающихся</a
            >
          </p>
          <p>
            <a href="https://disk.yandex.ru/i/lgeX1l9LG_k-nQ" target="_blank">
              Режим занятий обучающихся</a
            >
          </p>
          <p>
            <a href="https://disk.yandex.ru/i/tX9eQ-grgGsxAw" target="_blank">
              Форма, периодичность и порядок текущего контроля успеваемости и
              промежуточной аттестации обучающихся</a
            >
          </p>
          <p>
            <a href="https://disk.yandex.ru/i/GrT7JR8HxEFqoQ" target="_blank">
              Порядок и основание перевода, отчисления и восстановления
              обучающихся</a
            >
          </p>
          <p>
            <a href="https://disk.yandex.ru/i/Otao2e7aVlEbZQ" target="_blank">
              Порядок оформления возникновения, приостановления и прекращения
              образовательных отношений</a
            >
          </p>
        </div>
      </div>
      <div>
        <h2 @click="openBlock">Образование</h2>
        <div class="content">
          <h3>О РЕАЛИЗУМЫХ ОБРАЗОВАТЕЛЬНЫХ ПРОГРАММАХ</h3>

          <p>
            ООО &laquo;Лаборатория ЭИ&raquo; &nbsp;реализует образовательные
            программы дополнительного профессионального образования (повышение
            квалификации и профессиональная переподготовка) и дополнительные
            общеразвивающие программы.<br /><br />
            <a href="https://disk.yandex.ru/i/VG50jir7UQafxg" target="_blank"
              >Лицензия на осуществление образовательной деятельности</a
            ><br /><br />По всем программам предусмотрены следующие формы
            обучения:<br /><br />
          </p>
          <ul>
            <li>
              заочная с применение электронного обучения и дистанционных
              образовательных технологий
            </li>
          </ul>
          <p>
            <br />Язык образования &ndash; русский язык<br />Нормативный срок
            обучения устанавливается образовательной программой.<br />Лица с
            ограниченными возможностями здоровья отсутствуют, не обучаются.<br />Адаптированные
            образовательные программы не реализуются.<br />Образовательная
            программа представляет собой комплекс основных характеристик
            образования (объем, содержание, планируемые результаты),
            организационно-педагогических условий и форм аттестации, который
            представлен в виде:
          </p>
          <ul>
            <li>Учебного плана</li>
            <li>Учебно-тематического плана</li>
            <li>Пояснительной записки</li>
            <li>Содержания программ</li>
            <li>Календарного учебного графика</li>
            <li>Оценочных материалов (тесты и т. д.)</li>
            <li>
              Учебно-методических материалов (&laquo;электронная
              библиотека&raquo;).
            </li>
          </ul>

          <h3>ОПИСАНИЕ ОБРАЗОВАТЕЛЬНЫХ ПРОГРАММ</h3>
          <h3>Программы профессиональной переподготовки:</h3>

          <p>
            <a href="https://disk.yandex.ru/i/P1UdOIlm0dUqRQ" target="_blank">
              1. Эксперт диагностики и развития эмоционального интеллекта, 250 а.ч.</a
            >
          </p>
        </div>
      </div>

      <div>
        <h2 @click="openBlock">Руководство и педагогический состав</h2>
        <div class="content">
          <h3>Руководство:</h3>
          <p>
            Генеральный директора Лапина Юлия Михайловна <br />
            Контактный телефон:
            <a href="tel:+74959717302">8(495) 971-73-02</a><br />Адрес
            электронной почты:
            <a href="mailto:connect@eilab.ru">connect@eilab.ru</a>
          </p>
          <h3>Персональный состав педагогических работников:</h3>
        </div>
      </div>

      <div>
        <h2 @click="openBlock">Платные образовательные услуги</h2>
        <div class="content">
          <p>Порядок оказания платных образовательных услуг определяется</p>
          <p>
            <a href="https://disk.yandex.ru/i/C32AI64drOeQAA" target="_blank"
              >Положением об оказании платных образовательных услуг</a
            >
          </p>
          <p>
            <a href="https://disk.yandex.ru/i/pjZDgWBm8JJCcA" target="_blank"
              >Форма Договора оказания платных образовательных услуг</a
            >
          </p>
          <p>
            <a href="https://disk.yandex.ru/i/AuMUAmmsTnmdHA" target="_blank"
              >Приказ об утверждении базовой стоимости платных образовательных
              услуг в 2024 году</a
            >
          </p>
        </div>
      </div>

      <div>
        <h2 @click="openBlock">Международное сотрудничество</h2>
        <div class="content">
          <p>
            Заключенных и планируемых к заключению договоров с иностранными и
            (или) международными организациями по вопросам образования и науки
            нет.
          </p>
          <p>Международной аккредитации образовательных программ нет.</p>
        </div>
      </div>

      <div>
        <h2 @click="openBlock">Финансово-хозяйственная деятельность</h2>
        <div class="content">
          <p>
            ООО &laquo;Лаборатория ЭИ&raquo; не осуществляет деятельность за
            счет бюджетных ассигнований федерального бюджета, бюджетов
            Российской Федерации, местных бюджетов.
          </p>
          <p>
            Численность обучающихся по договорам об образовании за счет средств
            юридических и физических лиц: &nbsp;за период &nbsp;&nbsp;
          </p>
          <p>
            В соответствии с Уставом организации годовой отчет и годовой
            бухгалтерский баланс утверждается Генеральным директором
          </p>
          <p>План финансово-хозяйственной деятельности на 2022 гг.</p>
        </div>
      </div>

      <div>
        <h2 @click="openBlock">
          Материально-техническое обеспечение и оснащенность образовательного
          процесса
        </h2>
        <div class="content"></div>
      </div>

      <div>
        <h2 @click="openBlock">Вакантные места</h2>
        <div class="content">
          <p>
            ООО &laquo;Лаборатория ЭИ&raquo; не предоставляет вакантные места за
            счет бюджетных ассигнований федерального бюджета, бюджетов
            Российской Федерации, местных бюджетов.
          </p>
          <p>
            Набор в группы на обучение ведется в течение всего календарного
            года.
          </p>
          <p>Вакантные места в наличие в течение всего календарного года.</p>
        </div>
      </div>

      <div>
        <h2 @click="openBlock">Доступная среда</h2>
        <div class="content">
          <p>
            Специальные условия для обучения инвалидов и лиц с ограниченными
            возможностями здоровья отсутствуют, обучение не предусмотрено.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
export default {
  name: "information",
  setup() {
    useHead({
      title: "Сведения об организации",
    });

    const openBlock = (e) => {
      e.currentTarget.classList.toggle("open");
    };

    return {
      openBlock,
    };
  },
};
</script>

<style lang="scss" scoped>
.privacy-policy-wrapper {
  width: 100%;
  padding: 121px 25px 50px;
  font-size: 17px;
  line-height: 24px;
  color: #414352;

  .container {
    position: relative;
    margin: 0 auto;
    padding-top: 25px;
    max-width: 1170px;
  }

  .content > *,
  .content li {
    line-height: 0 !important;

    transition: all 0.4s ease-in-out;
    overflow: hidden;
  }

  .content p,
  .content h3,
  .content ul {
    margin: 0;
  }

  .content a {
    display: none;
  }

  h2.open ~ .content > *,
  h2.open ~ .content li {
    line-height: inherit !important;
    visibility: visible;
    overflow: visible;
  }

  h2.open ~ .content a {
    display: block;
  }

  h2.open ~ .content p,
  h2.open ~ .content h3,
  h2.open ~ .content ul {
    margin: 0 0 15px;
  }

  h2 {
    font-size: 24px;
    line-height: 1.2;
    margin: 10px 0 15px;
    position: relative;
    line-height: 150%;
    cursor: pointer;
    border-bottom: 1px solid var(--border-color);

    padding: 20px 15px 20px 0;
  }

  h2::after {
    position: absolute;
    display: block;
    content: "";
    right: 0;
    top: 50%;
    width: 9px;
    height: 9px;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    transform: rotate(45deg);

    transition: 0.3s transform;
  }

  h2:hover::after {
    border-right: 1px solid rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }

  h2:hover {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }

  h2.open::after {
    transform: rotate(225deg);
  }

  .head-text {
    line-height: 1.2;
    margin-bottom: 50px;
  }

  h1 {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 20px;
    font-weight: 600;
  }

  h3 {
    font-size: 20px;
    display: block;
  }

  p {
    margin: 0 0 15px;
  }

  ul,
  ol {
    margin: 0 0 15px;
    padding: 0 0 0 40px;
  }

  @media (max-width: 767px) {
    padding: 25px 15px 5px;

    .head-text {
      margin-bottom: 35px;
    }
    .container {
      padding: 35px 15px;
    }

    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 18px;
    }
  }
}
</style>